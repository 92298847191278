import React from 'react';

const NotInUse = () => (
  <form name="rsvp-form" method="POST" action="/success/" data-netlify="true" netlify-honeypot="got-ya">
    <input type="hidden" name="form-name" value="rsvp-form" />
    <input type="hidden" name="formId" value="rsvp-form" />

    {/* Main Guest First Name */}
    <div>
      <input id="mainGuestFirstName" name="mainGuestFirstName" placeholder="First Name" />
      <label htmlFor="mainGuestFirstName">First Name</label>
    </div>

    {/* Main Guest Last Name */}
    <div>
      <input id="mainGuestLastName" name="mainGuestLastName" placeholder="Last Name" />
      <label htmlFor="mainGuestLastName">Last Name</label>
    </div>

    {/* Main Guest Email */}
    <div>
      <input id="mainGuestEmail" name="mainGuestEmail" type="email" placeholder="Email Address" />
      <label htmlFor="mainGuestEmail">Email</label>
    </div>

    {/* Attending Selection */}
    <p>Will you be attending?</p>
    <div>
      <label htmlFor="attendingSelectionYes">
        <input type="radio" name="attendingSelection" value="yes" id="attendingSelectionYes" />

        <span>I'm in!</span>
      </label>
      <label htmlFor="attendingSelectionNo">
        <input type="radio" name="attendingSelection" value="no" id="attendingSelectionNo" />
        <span>Can't make it</span>
      </label>
    </div>

    {/* Phone Number */}
    <div>
      <input name="phoneNumber" placeholder="Phone Number" />
      <label htmlFor="phoneNumber">Phone Number</label>
    </div>

    {/* Plus One */}
    <p>Do you need a +1?</p>
    <div>
      <label htmlFor="plusOneSelectionYes">
        <input type="radio" name="plusOneSelection" value="yes" id="plusOneSelectionYes" />
        <span>Sure do!</span>
      </label>
      <label htmlFor="plusOneSelectionNo">
        <input type="radio" name="plusOneSelection" value="no" id="plusOneSelectionNo" />
        <span>Nope, just me</span>
      </label>
    </div>

    <div>
      <input id="plusOneName" name="plusOneName" placeholder="+1 Full Name" />
      <label htmlFor="plusOneName">+1 Full Name</label>
    </div>

    {/* Dietary Requirements */}
    <div>
      <input id="dietaryRequirements" name="dietaryRequirements" placeholder="Dietary requirements" />
      <label htmlFor="dietaryRequirements">Dietary Requirements</label>
    </div>

    {/* Have a song request? */}
    <div>
      <input id="songRequest" name="songRequest" placeholder="Song Request" />
      <label htmlFor="songRequest">Song Request</label>
    </div>

    {/* Captcha */}
    <label
      htmlFor="got-ya"
      style={{
        position: 'absolute',
        overflow: 'hidden',
        clip: 'rect(0 0 0 0)',
        height: '1px',
        width: '1px',
        margin: '-1px',
        padding: '0',
        border: '0',
      }}
    >
      Don’t fill this out if you're human:
      <input tabIndex="-1" name="got-ya" />
    </label>

    <button type="submit">Submit</button>
  </form>
);

export default NotInUse;
